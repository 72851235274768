
.dropdown {
	/* display: flex; */
	flex-direction: column;
	width: 100%;
	/* padding-left: 8px; */
}

.drop-button,
.dropdown > .drop-button {
	min-height: 65px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	max-height: 50px;
	min-width: 100px;
	width: 100%;
	padding: 0 16px;
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
}

.button-title
.dropdown > .drop-button > .button-title {
	display: flex;
	flex-direction: column;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 50%;
	/* align-items: ; */
}

.dropdown > .dropdown-list::-webkit-scrollbar {
    width: 5px;
    opacity: 0;
}

.dropdown > .dropdown-list::-webkit-scrollbar-button {
    display: none;
}

.dropdown > .dropdown-list::-webkit-scrollbar-thumb {
    border-radius: 50px;
	background: #F2F2F2EE;
    /* background: rgba(255,255,255,.4); */
}

.dropdown > .dropdown-list::-webkit-scrollbar-track {
    border-radius: 50px;
}

.dropdown > .dropdown-list::-webkit-scrollbar {
    display: none;
}

.dropdown > .dropdown-list:hover::-webkit-scrollbar {
    display: block;
}

.dropdown > .dropdown-list > .row-cell {
	height: 40;
}

.dropdown > .dropdown-list {
	overflow-Y: scroll;
	position: absolute;
	min-height: 0px;
	max-height: 200px;
	min-width: 100px;
	width: 100%;
	border-radius: 0 0 7px 7px;
	background: #222222EE;
	color: white;
	transition: max-height 0.2s ease-out;
	box-shadow: 0px 0px 1px #FFFFFF99;
}

.dropdown > .dropdown-list > .cardDrop {
		background: #FFFFFF;
}

.calendar-drop {
	visibility: hidden;
	transition: max-height 0.2s ease-in;
	max-height: 0px;
	padding: 0;
}

.calendar-drop.open {
	transition: max-height 0.2s ease-out;
	max-height: 250px;
	visibility: visible;
}