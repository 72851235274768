body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden; /*nascondo la scroll-bar su asse x*/
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

/* --- CUSTOM --- */

.calendar-col {
    flex: 0 0 auto;
    width: 14.285714%;
}

.calendar-col-7 {
    flex: 0 0 auto;
    width: 14.285714%;
}

.calendar-col-5 {
    flex: 0 0 auto;
    width: 20%;
}

    .calendar-col-5.weekend {
        display: none;
    }


.calendar-card {
    border: none;
    position: unset;
}

    .calendar-card > .card-title {
        text-align: center;
        font-weight: bolder;
    }

.calendar-progessbar {
    height: 5px;
}

.calendar-day {
    font-size: 2rem;
    color: #A6B7CC;
    text-align: left;
}

.add-element-calendar {
    /* background-color: #f5f6f8;
    color: #65667c;
    padding: 10px 30px 10px 30px;*/
    background-color: #f5f6f8;
    position: relative;
    width: 100%;
    border: 2px solid #A7B7CC;
    color: #65667c;
    border-radius: 7px;
}

    .add-element-calendar:hover {
        color: #000;
        background-color: #fff;
        border: 4px solid #4682b4;
    }


.calendar-activity {
    border: 2px solid #A7B7CC;
    border-radius: 7px;
    padding: 10px;
    margin: 5px 0px;
}

    .calendar-activity:hover,
    .add-project-drag-n-drop:hover {
        border: 4px solid #4682b4;
    }


.activity-project-description {
    font-size: 0.75rem;
    font-weight: bolder;
}

.activity-note {
    font-size: 0.65rem;
}

.activity-project-footer {
    font-size: 0.75rem;
    font-weight: bolder;
    margin-top: 10px;
    display: flex;
}

.footer-left {
    flex: 0 0 auto;
    width: 50%;
    text-align: left;
}

.footer-right {
    flex: 0 0 auto;
    width: 50%;
    text-align: right;
}

@media only screen and (max-width: 1200px) {
    /* For mobile phones: */
    .calendar-col-7, .calendar-col-5 {
        width: 80%;
        margin: 0% 10%;
    }
}

/* +++ HEADER +++ */

.account-name {
    /*font-family: 'Segoe UI', Arial, Helvetica, sans-serif;*/
    font-size: 20px;
    font-weight: bolder;
    background-color: transparent;
    color: #fff;
    text-decoration: none;
    margin: 0px;
}

.company {
    color: #a9a9a9;
    font-size: 15px;
    margin: 0;
}

.icon-letter {
    height: 60px;
    width: 60px;
    background-color: #fff;
    border-radius: 20px;
    color: #000;
    font-weight: bolder;
    vertical-align: central;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    font-size: 40px;
    text-align: center;
    padding-bottom: 5px;
}

.header {
    background-color: #0f1316;
    height: 138px;
}

.btn-logout {
    color: #fff;
    background-color: #0f1316;
    border-color: #0f1316;
}

    .btn-logout:hover {
        color: #fff;
        background-color: #0f1316;
        border-color: #0f1316;
    }


.sticky-wrapper {
    position: relative;
    height: 3rem; /* We need to change this value */
}

.sticky .sticky-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: white;
}

.dark-banner {
    border-radius: 5px;
    margin: 0 auto;
    color: white;
}

header .d-flex button:focus {
    box-shadow: none;
}

.border-button {
    border-bottom: 5px solid #ffffff !important;
    padding-bottom: 2px !important;
}
/* NICHOLAS */
.btn.btn-outline-light {
    position: relative;
    width: 100%;
    border: 2px solid #A6B7CC;
    color: black;
    border-radius: 7px;
}

    .btn.btn-outline-light:hover {
        background-color: white;
    }

    .btn.btn-outline-light:active {
        background-color: white;
    }

.add-project-drag-n-drop {
    position: absolute;
    border-Radius: 10px;
    width: 15px;
    height: 15px;
    border: 2px solid #A7B7CC;
    bottom: -7px;
    right: -7px;
    background-Color: white;
}


    .add-project-drag-n-drop:hover {
        background-color: white;
    }

.divHiddenButton {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 25px;
}

.btn-outline-primary {
    border: 2px solid #A6B7CC;
    background-color: #fff;
    border-radius: 7px;
    color: #A6B7CC;
}

    .btn-outline-primary:hover {
        color: #fff;
        background-color: #A6B7CC;
        border-color: #A6B7CC;
    }

.active-element {
    display: block;
}

.not-active-element {
    display: none;
}


/* Costumization OffCanvas */
div::-webkit-scrollbar {
    display: none;
}


.custom-color-border {
    border: 0.5px solid #4781b3;
    border-radius: 3px;
}

/*.offcanvas {*/ /*sposta l'offcanvas in basso tramite il margine in alto di 138px*/
/*margin-top: 138px;
}*/

.searchable-item-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px
}

.offcanvas-style {
    width: 450px !important;
    padding: 30px;
}

.color-picker {
    width: 30px;
    height: 30px;
    border: 1px solid #888;
}


.number-size {
    font-size: 22px;
    margin-top: -7px !important;
}

.text-size {
    font-size: 12px;
}

.day-week {
    color: #4781b3;
    font-weight: bold;
}

.text-gray {
    color: rgb(189, 189, 189);
}

.costo-km {
    width: 50px;
    height: 50px;
    background-color: #888;
}

input[type="color"] {
    padding: 0px;
    border: none;
}

.number-size {
    font-size: 22px;
    margin-top: -7px !important;
}

.text-size {
    font-size: 12px;
}

.day-week {
    color: #4781b3;
    font-weight: bold;
}

.text-gray {
    color: rgb(189, 189, 189);
}

.costo-km {
    width: 50px;
    height: 50px;
    background-color: #888;
}

.btn-primary-custom:active,
.btn-primary-custom:focus,
.btn-primary-custom {
    color: #fff;
    border-radius: 7px;
    background-color: #4781b3;
    border: 2px solid #4781b3;
}

    .btn-primary-custom:hover {
        color: #fff;
        border-radius: 7px;
        background-color: #45718d;
        border: 2px solid #446b80;
    }

.btn-select-day {
    padding: 12px;
}

.offcanvas-body {
    width: 100%;
    /* flex-direction: row; */
    /* justify-content: space-between; */
    padding: 1rem 1rem;
}

.offcanvas-footer {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1rem;
}

.item-off-canvas-component {
    margin: 25px 0px;
}

    .item-off-canvas-component > p {
        margin: 0px;
    }

.today {
    background-color: #4781b3;
    color: #fff;
}

.day {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    text-align: center;
    vertical-align: central;
    margin: 5px 0px;
}

/*Css button day select*/
div .color-button button {
    color: #fff;
}

    div .color-button button:hover {
        color: #888 !important;
    }

#styled-select {
    width: 260px;
}

.box-inputs {
    position: relative;
}

    .box-inputs .icon {
        position: absolute;
        height: 25px;
        width: 20px;
        left: 10px;
        top: 10px;
        z-index: 2;
    }

.input-with-icon {
    padding-left: 35px !important;
}




.loader {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #fff;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
    z-index: 2010;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.hidden {
    display: none;
}

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 2000;
}



.date-picker {
    width: auto;
}

.react-datepicker { /* DA CONTROLLARE SE CREA PROBLEMI */
    z-index: 100;
}


/*Scheduler*/

.selected-account,
.selected-date,
.selected-month {
    margin-top: 10px;
    font-size: 15px;
    border: 2px solid #4781b3;
    box-shadow: 0px 0px 3px 3px #4781b3;
    border-radius: 10px;
    text-align: center;
    padding: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0; /* 0.375rem 0.75rem;*/
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.deselected-account,
.deselected-month {
    border: 0.5px solid #4781b3;
    /*box-shadow: 0px 0px 1px 1px #4781b3;*/
    border-radius: 0.25rem;
}

    .deselected-account:hover,
    .deselected-month:hover {
        box-shadow: 0px 0px 1px 1px #4781b3;
    }

form .input-accountlist {
    margin: 0 auto;
}

.date-picker-position {
    position: absolute;
}

.border-color-none {
    border-color: transparent !important;
    box-shadow: none !important;
    margin: 0 5px !important;
}

.error-message {
    border-radius: 5px;
}
/*.thead-scheduler {
    border-bottom-color: transparent !important;
}*/
/* blocco l'altezza del corpo della tabella e visualizzo la scrollbar */
/*.tbody-scheduler {
    height: 200px;
    overflow: auto;*/
/*display: table-caption;*/
/*}*/
/*.thead-scheduler th,
    .tbody-scheduler td {
        width: 25%;
    }*/
/*Tabella JobOrder*/
/* Stile generale tabella */
.job-order-table {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    display: block;
    /* font-size: 125%;*/
    white-space: nowrap;
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    border: 1px solid #cdd6e2;
}

    /* Aggiungo contorno ad ogni cella */
    .job-order-table td,
    .job-order-table th {
        border: 1px solid #cdd6e2;
        padding: 0.5rem 1rem;
        text-align: left;
    }
    /* Rendo sticky la parte sopra della tabella */
    .job-order-table thead th {
        padding: 10px 20px;
        position: sticky;
        top: 0;
        z-index: 1;
        width: 25vw;
        background: white;
        border: 1px solid #a7b7cc;
    }

.job-order-table-scheduler tbody tr:last-child td {
    border: none !important;
}


.job-order-table-scheduler thead tr th {
    padding: 10px 8px;
    position: sticky;
    top: 0;
    z-index: 1;
    /*width: 25vw;*/
    background: #f4f4f9;
    border-bottom-color: transparent !important;
}

.job-order-table-scheduler tbody tr {
    border-top-color: grey;
}

/*.job-order-table-scheduler tbody {
   display:block;
   overflow: auto;
}*/
/*tabella dello scheduler*/
.job-order-table-scheduler {
    vertical-align: middle;
    /*border: 1px solid gray;*/
    border-collapse: collapse;
    border-radius: 6px;
    max-height: 200px;
    /*table-layout: fixed;*/
}

/*.job-order-table-scheduler td, th {
    border-left: solid black 1px;
    border-top: solid black 1px;
}*/

/*.job-order-table-scheduler td {
    border-bottom: none;
}*/

/*.job-order-table-scheduler td:first-child, th:first-child {
        border-left: none;
    }*/
/* setto il background color al body e lo formatto un po' */
.job-order-table tbody {
    background: #f4f6f8;
    padding: 4px 5px;
    /* text-align: left; */
}
    .job-order-table tbody td {
        font-weight: normal;
        text-align: right;
        position: relative;
    }

    /* formatto il testo delle colonne fisse nel Tbody */
    .job-order-table tbody th {
        font-weight: normal;
        text-align: left;
        position: relative;
    }

/* rendo sticky il primo elemento della top row */
.job-order-table thead th:first-child {
    position: sticky;
    width: 120px;
    left: 0;
    z-index: 2;
    border-right: 2px solid #4682b4;
}

/* rendo le colonne del Tbody che voglio fisse */
.job-order-table tbody th {
    position: sticky;
    left: 0;
    background: #f4f6f8; /*serve perch� senno il testo si vede sotto la colonna*/
    z-index: 1;
    border-right: 2px solid #4682b4;
}


/* width */
#divWithCustomScrollBar::-webkit-scrollbar {
    width: 15px;
    height: 15px;
}


/* Track */
#divWithCustomScrollBar::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 100px;
}

/* Handle */
#divWithCustomScrollBar::-webkit-scrollbar-thumb {
    background: #202124;
    border-radius: 100px;
    border: 4px solid transparent;
    background-clip: content-box;
}

    /* Handle on hover */
    #divWithCustomScrollBar::-webkit-scrollbar-thumb:hover {
        background: #202124;
        border: 4px solid transparent;
        background-clip: content-box;
    }

/*.outsideTable {
    max-height: 297px;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: auto;
}*/

.tableas {
    max-height: 297px;
    overflow: auto;
}

.hideCol {
    display: none;
}

.showCol {
    background-color: #dce3ed;
}

.react-datepicker { /* DA CONTROLLARE SE CREA PROBLEMI */
    z-index: 100
}

.job-order-table thead th:nth-child(2) {
    border-left: 2px solid #4682b4;
}

/* Stile generale tabella */
.customTableDiv table {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    display: block;
    /* font-size: 125%;*/
    white-space: nowrap;
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    border: 1px solid #cdd6e2;
    /*border-radius: 7px;*/
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    max-height: 400px;
    overflow: auto;
}

    /* Aggiungo contorno ad ogni cella */
    .customTableDiv table td {
        padding: 20px 20px;
        text-align: right;
        border-bottom: 1px solid #dee0e3;
    }
    .customTableDiv table th {
        /* border: 1px solid #cdd6e2; */
        padding: 20px 20px;
        text-align: left;
        border-bottom: 1px solid #dee0e3;
    }
    /* Rendo sticky la parte sopra della tabella */
    .customTableDiv table thead th {
        padding: 20px 20px;
        position: sticky;
        top: 0;
        z-index: 1;
        width: 25vw;
        background: white;
        border-bottom: 1px solid #dee0e3;
        /* border: 1px solid #a7b7cc; */
    }

    /* setto il background color al body e lo formatto un po' */
    .customTableDiv table tbody {
        text-align: left;
    }

        /* formatto il testo delle colonne fisse nel Tbody */
        .customTableDiv table tbody th {
            font-weight: normal;
            text-align: left;
            position: relative;
            border-right: 1px solid #dee0e3;
        }

    /* rendo sticky il primo elemento della top row */
    .customTableDiv table thead th:first-child {
        position: sticky;
        width: 120px;
        left: 0;
        z-index: 2;
        border-right: 1px solid #dee0e3;
        /*box-shadow: 10px 0 10px -1px rgb(0 0 0 / 0.1);*/
    }

    /* rendo le colonne del Tbody che voglio fisse */
    .customTableDiv table tbody th {
        position: sticky;
        left: 0;
        background: white; /*serve perch� senno il testo si vede sotto la colonna*/
        z-index: 1;
        /*box-shadow: 10px 0 10px -1px rgb(0 0 0 / 0.1);*/
    }


    /*Personalizzo il footer*/
    .customTableDiv table tfoot th {
        /*        font-weight: normal;*/
        text-align: left;
        border-right: 1px solid #dee0e3;
        position: sticky;
        left: 0;
        background: white; /*serve perch� senno il testo si vede sotto la colonna*/
        z-index: 1;
        /*box-shadow: 10px 0 10px -1px rgb(0 0 0 / 0.1);*/
    }

.customBG {
    background-color: #f4f4f9 !important;
}

.datesList {
    border-left: 1px solid #dee0e3;
}

    .datesList ~ .datesList {
        border-left: none;
    }

.cardInfo {
    padding: 15px;
}

    .cardInfo div h2 {
        font-weight: bold;
    }


.activityName {
    padding: 15px;
}

    .activityName div h2 {
        font-weight: bold;
    }


.customTableInput {
    width: 100px;
    border: none;
    padding: 5px;
    border-radius: 5px;
}

    .customTableInput:hover {
        background-color: #e5eaf0;
        border-radius: 5px;
        cursor: pointer;
    }

    .customTableInput:focus-visible {
        outline: none;
        cursor: text;
    }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.customStatusTableDiv table {
    border: 1px solid #dddee2;
    border-bottom: none;
    table-layout: fixed;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-spacing: 0;
    border-collapse: separate;
    margin: 0;
    display: block;
}

    .customStatusTableDiv table thead th {
        padding: 10px 10px;
    }

    .customStatusTableDiv table tbody td {
        padding: 15px 10px;
    }

    .customStatusTableDiv table td,
    .customStatusTableDiv table th {
        /*padding: 10px 10px;*/
        text-align: right;
        border: none;
        width: 300px;
        overflow: hidden;
        border-bottom: 1px solid #dddee2;
    }

    .customStatusTableDiv table thead th:first-child {
        border-top-left-radius: 7px;
        border-collapse: separate;
    }

    .customStatusTableDiv table thead th:last-child {
        border-top-right-radius: 7px;
        border-collapse: separate;
    }

    .customStatusTableDiv table td:first-child {
        text-align: left;
    }

    .customStatusTableDiv table thead {
        background-color: #f8f8fb;
    }

        .customStatusTableDiv table thead th:nth-child(2) {
            color: #7cc3ff;
        }

        .customStatusTableDiv table thead th:nth-child(3) {
            color: #e17289;
        }

    .customStatusTableDiv table tbody td:nth-child(2) {
        background-color: #7cc3ff;
        border-right: 1px solid white;
    }

    .customStatusTableDiv table tbody td:nth-child(3) {
        background-color: #e17289;
    }

/*margine sotto del calendario su un div finto*/
.row-calendar-page {
    margin-bottom: 100px;
}

/* pagination child component style*/
.pagination-link.active {
    border-color: #0d6efd;
}

/*    .pagination-link:hover {
        z-index: 2;
        color: #000;
        background-color: #fff;
        border-color: #fff;
    }
*/

.page-link-report {
    padding: 0.375rem 0.75rem;
    position: relative;
    display: block;
    color: #1c1f23 !important;
    text-decoration: none;
    background-color: #fff !important;
    border: 1px solid #1c1f23 !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

    .page-link-report:hover {
        color: #000 !important;
        background-color: #fff !important;
        cursor: pointer;
    }


    .page-link-report:focus {
        z-index: 3;
        /*color: #000 !important;
        background-color: #fff !important;*/
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    }

.page-item.active .page-link-report {
    color: #fff !important;
    background-color: #1c1f23 !important;
}
/*.page-item:not(:first-child) .page-link-report {
    margin-left: -1px;
}*/


.hiddenRow {
    padding: 0 !important;
}

.total-expenses > .cardInfo {
    padding: 5px;
}

    .total-expenses > .cardInfo > div > h6 {
        font-size: 0.75rem;
    }

    .total-expenses > .cardInfo > div > h2 {
        font-size: 1.30rem;
    }

.requestTable table {
    border: 1px solid #dddee2;
    border-bottom: none;
    white-space: nowrap;
    table-layout: fixed;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-spacing: 0;
    border-collapse: separate;
    margin: 0;
    display: block;
}

    .requestTable table thead th {
        padding: 10px 10px;
    }

    .requestTable table tbody td {
        padding: 15px 10px;
    }

    .requestTable table td,
    .requestTable table th {
        /*padding: 10px 10px;*/
        text-align: center;
        border: none;
        width: 300px;
        overflow: hidden;
        border-bottom: 1px solid #dddee2;
    }

    .requestTable table thead th:first-child {
        border-top-left-radius: 7px;
        border-collapse: separate;
    }

    .requestTable table thead th:last-child {
        border-top-right-radius: 7px;
        border-collapse: separate;
    }

    .requestTable table td:first-child {
        text-align: center;
    }

    .requestTable table thead {
        background-color: #f8f8fb;
    }

    .requestTable table tbody:last-child {
        text-align: center;
    }

.btn-day-type-selected {
    color: #fff;
    border-radius: 7px;
    background-color: #4781b3;
    border: 2px solid #4781b3;
}

    .btn-day-type-selected:hover {
        color: #fff;
        border-radius: 7px;
        background-color: #4781b3;
        border: 2px solid #4781b3;
    }

.btn-day-type {
    color: #4781b3;
    border-radius: 7px;
    background-color: #fff;
    border: 2px solid #4781b3;
}

    .btn-day-type:hover {
        color: #4781b3;
        border-radius: 7px;
        background-color: #fff;
        border: 2px solid #4781b3;
    }

.requestSelectorTitle {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-size: small;
    color: gray;
}

.salesTable table {
    border: 1px solid #dddee2;
    border-bottom: none;
    white-space: nowrap;
    table-layout: fixed;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-spacing: 0;
    border-collapse: separate;
    margin: 0;
    display: block;
}

    .salesTable table thead th {
        padding: 10px 10px;
    }

    .salesTable table tbody td {
        padding: 15px 10px;
    }


    .salesTable table td,
    .salesTable table th {
        /*padding: 10px 10px;*/
        text-align: center;
        border: none;
        width: 300px;
        overflow: hidden;
        border-bottom: 1px solid #dddee2;
    }

    .salesTable table thead th:first-child {
        border-top-left-radius: 7px;
        border-collapse: separate;
    }

    .salesTable table thead th:last-child {
        border-top-right-radius: 7px;
        border-collapse: separate;
    }

    .salesTable table td:first-child {
        text-align: center;
    }

    .salesTable table thead {
        background-color: #f8f8fb;
    }

    .salesTable table tbody:last-child {
        text-align: center;
    }

/*fix to menu tabs*/
.removeBorder,
.removeBorder:active,
.removeBorder:focus,
.removeBorder:focus-visible,
.removeBorder:hover {
    border: 1px solid transparent;
}

/*fix backgroud to all black button*/
.btn-check:focus + .btn-dark, .btn-dark:focus, .btn-dark:hover {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21;
}

/*fix to favourite color when click star*/
.borderFix {
    height: 100%;
    padding-right: 3px !important;
}

/*fix backgroud to dropdown elements*/
.btn-dark-outline:active,
.btn-dark-outline:focus,
.btn-dark-outline:focus-visible,
.btn-dark-outline:hover {
    border: 1px solid transparent;
}

/*fix background to day select*/
div .color-button button,
div .color-button button:active,
div .color-button button:focus,
div .color-button button:focus-visible,
div .color-button button:hover {
    border: 1px solid transparent;
}

/*remove border and hover effect to addActivity/addExpense*/
.rmvBorder:active,
.rmvBorder:focus,
.rmvBorder:focus-visible {
    background-color: #f5f6f8;
    position: relative;
    width: 100%;
    border: 2px solid #a7b7cc;
    color: #65667c;
    border-radius: 7px;
}

/*remove hover effect from location and hours selector*/
.rmvHover,
.rmvHover:active,
.rmvHover:focus,
.rmvHover:focus-visible,
.rmvHover:hover {
    border: 1px solid transparent;
    background-color: #f8f9fa;
}

/*Remove bold from current month*/
.react-datepicker__month-text--today {
font-weight:normal !important;
}
/*remove all shadows from button and input*/
button,
input {
    box-shadow: none !important;
}
