
.custom-grid {
	/* display: flex; */
	min-height: 300px;
	max-height: 100%;
	flex-direction: column;
	overflow: auto !important;
	min-width: 200px;
	font-family: 'Open Sans', sans-serif;
	box-shadow: 0px 0px 5px rgba(0,0,0,0.4);
	border-radius: 2px;
    transition: max-height 0.2s ease-out;
	flex-grow: 1;
}

.custom-grid:last-child {
	flex-grow: 3;
}

.custom-grid::-webkit-scrollbar {
    width: 2px;
    height: 2px;
	background: transparent;
	border-radius: 2px;
}

.custom-grid::-webkit-scrollbar-button {
    display: none;
}

.custom-grid::-webkit-scrollbar-thumb {
    background: #2F2F2FAA;
	border-radius: 2px;
}

.custom-grid::-webkit-scrollbar-track {
	border-radius: 2px;
	background: transparent;
}

.custom-grid::-webkit-scrollbar {
    display: none;
	background: transparent;
}

.custom-grid:hover::-webkit-scrollbar {
    display: block;
	background: transparent;
}

.custom-grid > .grid-row,
.custom-grid > * > .grid-row {
	display: flex;
	flex-wrap: nowrap;
	min-height: 65px;
	max-height: 165px;
	align-items: flex-start;
	justify-content: space-between;
	/* box-shadow: 0 1px 0px 0px rgba(0,0,0,0.2); */
	max-width: 100%;
	border-radius: 2px;
}

.custom-grid > .grid-row:hover,
.custom-grid > * > .grid-row:hover {
	background: #EEEEEE;
	/* max-width: 100vw; */
}

.custom-grid > .header-row,
.custom-grid > * > .header-row {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	display: flex;
	flex-direction: row;
	/* border-radius: 2px; */
}

.row-cell,
.grid-row > .row-cell {
	display: flex;
	min-height: 65px;
	max-height: 100px;
	align-items: center;
	min-width: 100px;
	text-overflow: ellipsis;
    word-break: normal;
	padding: 12px;
	width: 100%;
	box-shadow: 0px 0px 0px 1px 1px #666666;
	background: inherit;
}

/* .selected-row > *, */
.row-cell.header-cell.save-button.active,
.drop-button.selected-row,
.grid-row.selected-row > .row-cell{
	background: linear-gradient(rgba(0, 122, 255, 0.9), rgba(0, 132, 255, 1), rgba(0, 122, 255, 0.9));
	color: white;
	box-shadow: 0px 0px -1px 5px #666666;
}

.header-cell,
.header-row > .header-cell {
	padding: 0;
	box-shadow: 0px 0px 2px #666666;
	background: #0F1316EE;
	min-height: 65px;
	color: white;
	min-width: 100px;
}

.header-row > .header-cell:first-child {
	border-radius: 2px 0 0 2px;
}

.header-row > .header-cell:last-child {
	border-radius: 0 2px 2px 0;
}

.header-row > .header-cell:only-child {
	border-radius: 2px;
}

.save-button {
	max-width: 100px;
	align-items: center;
	justify-content: center;
	-webkit-user-select: none;
	user-select: none;
	cursor: pointer;
}

.delete-button {
	max-width: 50px;
	align-items: center;
	justify-content: center;
	-webkit-user-select: none;
	user-select: none;
	cursor: pointer;
	color: #FF1010AA;
}

.row-cell.header-cell.selected-count {
	padding: 0 12px;
}

.searchInput {
	background: #22222200;
	outline: none;
	width: 100%;
	border: 0;
	/* line-height: 2.5rem; */
	/* border-radius: 2px; */
	padding: 12px;
	color: white;
}

.searchInput:hover {
	outline: none;
	border: 0;
}

.searchInput:focus {
	outline: none;
	border: 0px;
	/* box-shadow: 0px 0px 2px rgba(0,0,0,0.2); */
}